<template>
	<div class="section1">
		<div class="cate-box">
			<h2 class="title">活动公告</h2>
			<!-- <router-link to="/news?id=2" class="more">更多<span class="yuandian">+</span></router-link> -->
		</div>
		<ul class="list">
			<!-- <router-link :to="'/newsInfo?id='+item.cate_id+'&aid='+item.id" v-for="(item,index) in list" class="item clearfix" :key="index">
				<h2 class="title fl">{{item.title}}</h2>
				<time class="time fr">{{item.addtime}}</time>
				<span class="tab fr" v-if="item.top == 1">置顶</span>
			</router-link> -->
			
			<div class="swiper" v-if="list.length>0">
				<!-- <div class="icon"><img src="../../../assets/images/icon9.png" ></div> -->
				<wd-swipe hide-indicators>
				<wd-swipe-item v-for="(item,index) in list" :key="index">
					 <div :data-src="'/newsInfo?id='+item.cate_id+'&aid='+item.id" class="item" @click="navto">{{ item.title }}</div>
					 <!-- <router-link :to="'/newsInfo?id='+item.cate_id+'&aid='+item.id" class="item" >{{ item.title }}</router-link> -->
				</wd-swipe-item>
				</wd-swipe>
			</div>
			<div class="nolist" v-if="list.length==0">暂无相关公告</div>
			
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: []
			}
		},
		created() {
			this.getList();
		},
		methods: {
			GetUrlParam(src,paraName) {
			var url = src;
			var arrObj = url.split("?");
			if (arrObj.length > 1) {
			var arrPara = arrObj[1].split("&");
			var arr;
			for (var i = 0; i < arrPara.length; i++) {
			arr = arrPara[i].split("=");
			if (arr != null && arr[0] == paraName) {
			return arr[1];
			}
			}
			return "";
			}
			else {
			return "";
			}
			},
			getUrl(url){
			return new Promise((resolve,reject)=>{
			let aurl = url.match(/(\S*)\?/)[1]
		
			resolve(aurl)
			})

			},
			navto(e){
			let path = e.target.dataset.src;
			let url = 'https://tanpei.ks265.net/#'+path;
			let id = this.GetUrlParam(url,'id');
			let aid = this.GetUrlParam(url,'aid');
			this.getUrl(url).then(resolve=>{
				let src = resolve;
				wx.miniProgram.getEnv(function(res){
      					 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/newinfo/newinfo?src='+src+'&id='+id+'&aid='+aid});
               			}else{
						
						}
      				  });
						})
				},
			// 培训公告
			async getList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/newsList',{params:{ cateId: 2,limit: 3 }});
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('首页培训公告：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var list = res.data.lists;
					for(var i = 0; i < list.length; i++) {
						list[i]['addtime'] = this.utils.js_date_time(list[i]['addtime'],1);
					}
					this.list = list;
				}
			},
		}
	}
</script>

<style scoped>
	.section1{
	padding: .25rem 0;
	}
	.section1 .cate-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 0.88rem;
		padding: 0 0.3rem;
	}
	.section1 .cate-box .title{
		font-size: 0.32rem;
		color: #333333;
		padding-left: 0.5rem;
		position: relative;
		background: url(../../../assets/images/gonggao.png) no-repeat;
		background-size: 20px;
	}

	.section1 .cate-box .more{
		font-size: 0.28rem;
		color: #333333;
	}
	.section1 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		width: 92%;
		margin: 0 auto;
		padding: 0 .2rem;
		border-radius: .1rem;
	}
	.section1 .list .item{
		display: block;
		
		border-top: 0.02rem solid #e0e0e0;
		color: #000;
		
	}
	.section1 .list {
		padding-left: .1rem;
	}
	.section1 .list .item:first-child{
		border-top: none;
	}
	.section1 .list .item .title{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.38rem;
		padding-left: 0.5rem;
		max-width: 4.5rem;
	}
	.section1 .list .item .time{
		font-size: 0.24rem;
		color: #c7c7c7;
		line-height: 0.38rem;
	}
	.section1 .list .item .tab{
		width: 0.64rem;
		height: 0.38rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.02rem solid #94c340;
		font-size: 0.2rem;
		color: #94c340;
		margin-right: 0.16rem;
		line-height: 0.38rem;
	}
	.yuandian{
		font-size: .25rem;
	}
	.swiper{
	height: .8rem;
	display: flex;
	align-items: center;
	line-height: .8rem;

	}
	.swiper .wd-swipe{
		width: 100%;
		height: 100%;
	}
	.swiper .icon{
		display: inline-block;
		width: .4rem;
		height: .4rem;
		line-height: .4rem;
		margin-right: .1rem;
	}
	.swiper img{
	transform: translateY(0.05rem);
	}
	.swiper .wd-swipe-item{
		font-size: .2rem;
		
	}
	.item{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: .26rem;
	}
/deep/.swiper .wd-swipe .wd-swipe__container{
	height: 100%;
}
</style>
