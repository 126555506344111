<template>
	<div class="section1">
		<div class="cate-box">
			<h2 class="title">{{title}}</h2>
			<!-- <router-link to="/help?id=9" class="more">更多<span class="yuandian">+</span></router-link> -->
		</div>
		<ul class="list">
			<div :data-id="item.cate_id" :data-aid="item.id" v-for="(item,index) in list" class="item clearfix" :key="index" @click="navTo">
				<h2 class="title fl">{{item.title}}</h2>
				<!-- <time class="time fr">{{item.addtime}}</time> -->
				<span class="tab fr" v-if="item.top == 1">置顶</span>
			</div>
			
			<!-- <div class="swiper">
				<div class="icon"><img src="../../../assets/images/icon9.png" ></div>
				<wd-swipe hide-indicators>
				<wd-swipe-item v-for="(item,index) in list" :key="index">
					
				
					{{ item.title }}
				</wd-swipe-item>
				</wd-swipe>
			</div> -->
			
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				title:''
			}
		},
		created() {
			this.getList();
			this.getTitle()
		},
		methods: {
			// 操作指南
			async getList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/article/helpList',{cateId:9});
				console.log(res);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('首页操作指南：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					// var list = res.data.lists;
					// for(var i = 0; i < list.length; i++) {
					// 	list[i]['addtime'] = this.utils.js_date_time(list[i]['addtime'],1);
					// }
					this.list = res.data.lists;
				}
			},
			async getTitle() {
				
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/helpCateDetail',{params:{id:9}});
				console.log(res);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('首页操作指南：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					// var list = res.data;
					// for(var i = 0; i < list.length; i++) {
					// 	list[i]['addtime'] = this.utils.js_date_time(list[i]['addtime'],1);
					// }
					// console.log(res);
					// this.title = res.data.title;
				}
				
			},
			navTo(e){

			let url = 'https://tanpei.ks265.net/#/guidanceInfo?id='
			 wx.miniProgram.getEnv(function(res){
      		 if (res.miniprogram){
			    wx.miniProgram.navigateTo({url:'/pages/webview/web?src='+url});
              }else{
						
			 }
      		});
			}
			
		}
	}
</script>

<style scoped>
	.section1{
		
	}
	.section1 .cate-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 0.88rem;
		padding: 0 0.3rem;
	}
	.section1 .cate-box .title{
		font-size: 0.32rem;
		color: #333333;
		padding-left: 0.5rem;
		position: relative;
		background: url(../../../assets/images/zhiyin.png) no-repeat;
		background-size: 20px;
		
	}

	.section1 .cate-box .more{
		font-size: 0.28rem;
		color: #333333;
	}
	.section1 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		width: 92%;
		margin: 0 auto;
		padding: 0 .2rem;
		border-radius: .1rem;
	}
	.section1 .list .item{
		display: block;
		padding: 0.3rem 0rem;
		border-top: 0.02rem solid #e0e0e0;
	}
	.section1 .list {
		padding-left: .1rem;
	}
	.section1 .list .item:first-child{
		border-top: none;
	}
	.section1 .list .item .title{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.38rem;
		padding-left: 0.5rem;
		max-width: 4.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		/* background: url(../../../assets/images/icon9.png) no-repeat left center; */
		background-size: 0.42rem 0.42rem;
	}
	.section1 .list .item .time{
		font-size: 0.24rem;
		color: #c7c7c7;
		line-height: 0.38rem;
	}
	.section1 .list .item .tab{
		width: 0.64rem;
		height: 0.38rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.02rem solid #94c340;
		font-size: 0.2rem;
		color: #94c340;
		margin-right: 0.16rem;
		line-height: 0.38rem;
	}
	.yuandian{
		font-size: .25rem;
	}
	.swiper{
	height: .8rem;
	display: flex;
	align-items: center;
	line-height: .8rem;
	}
	.swiper .wd-swipe{
		width: 100%;
	}
	.swiper .icon{
		display: inline-block;
		width: .4rem;
		height: .4rem;
		line-height: .4rem;
		margin-right: .1rem;
	}
	.swiper img{
	transform: translateY(0.05rem);
	}
	.swiper .wd-swipe-item{
		white-space: nowrap;
		font-size: .2rem;
		overflow: hidden;
		    margin-top: .3rem;
	}

</style>
