<template>
	<div class="section2">
<!-- 		<div class="cate-box">
			<ul class="cate-list">
				<span class="item" :class="[curIndex==index?'active':'']" v-for="(item,index) in cateList" @click="curIndex = index" :key="index">{{item.title}}</span>
			</ul>
		</div> -->
		<template v-if="curIndex == 0">
			<ul class="list">
				
				<li v-for="(item,index) in newsList" class="item" :key="index" >
					<div class="listmask" :data-id="item.cate_id" :data-aid="item.id"  @click="openmask(index)"></div>
					<div class="pic">
						<img class="img" :src="item.img" />
					</div>
					<div class="content" >
						<h2  class="title" :style="{ paddingRight: item.top == 1 ? '0.6rem':'0' }">{{item.title}}</h2>
						<div  class="desc">{{item.desc}}</div>
						<div  class="time">
							<div class="timer">{{item.addtime}}</div>
							<div class="icon"><img :src="icon" alt="">{{item.num}}</div>
						</div>
					</div>
				</li>
			</ul>
		</template>

				<!-- 视频弹出层 -->
		<div class="mask" v-if="showMask"></div>
		<div class="alert-box" :class="[ showMask ? 'active' : '' ]">
			<div class="title">{{videoTitle}}
				<img class="close" @click="closeMask" src="@/assets/images/menu_close.png" />
			</div>
			<div class="alert-video-box" v-if="showMask">
				<video-player class="video-player vjs-custom-skin"
					ref="videoPlayer"
					:playsinline="true"
					:oncontextmenu ="false"
					@loadeddata="onPlayerLoadeddata"
					controlslist="nodownload"
					:options="playerOptions">
				</video-player>
			</div>
		</div>
	</div>
</template>

<script>
	import icon from '@/assets/images/icon15.png';
	import { videoPlayer } from 'vue-video-player';
	export default {
		components:{
			videoPlayer
		},
		data() {
			return {
				newsList:'',
				icon:icon,
				videoTitle: '',
				playerOptions: {
					playbackRates: [1.0], // 可选的播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{}],
					poster: '', // 封面地址
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: false, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					}
				},
				showMask: false,
				cateList: [
					{
						title: '碳排放资格证',
						id: 8
					},
					{
						title: '法律法规',
						id: 3
					}
				],
				curIndex: 0,
				cIndex:0,
				list: {},
				
				list1: {}
			}
		},
		created() {
		// this.getNewsList();
		},
		methods: {
				openmask(index){
				var newsList = this.newsList;
				this.cIndex = index;
				// if (index!==newsList.length-1) {
				this.videoTitle = newsList[index]['title'];
				this.playerOptions.poster = newsList[index]['img'];
				this.playerOptions.sources[0]['src'] = newsList[index]['video'];
				this.showMask = true;
				// }else{
				// 	let nid = newsList[index].id;
				// 	let uid = this.$store.state.uid;
				// 	wx.miniProgram.getEnv(function(res){
      			// 		if (res.miniprogram){
				// 		 wx.miniProgram.navigateTo({url:'/pages/newinfo/newinfo?src=https://tanpei.ks265.net/#/newsInfo'+'&aid='+nid+'&id='+uid});
               	// 		}else{
							   
				// 		}
      			// 	  });
				// }
			},
			closeMask(){
				this.showMask = false;
				},
				onPlayerLoadeddata(player){
				if(this.$store.state.beta){
					console.log('列表页弹窗视频加载完成：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
				}
				player.play();
				console.log(this.$refs.videoPlayer);
				// this.$refs.videoPlayer.$attrs('controlslist','nodownload')
				this.addnum();
				
			
			},
				// 增加视频播放量

			async addnum(){
				var id = this.newsList[this.cIndex]['id'];
				var data = {
					id
				}
				const { data:res } = await this.$http.get('/gongyi/addnum', { params: data });
				if(this.$store.state.beta){
					console.log('列表页增加视频播放量：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.newsList[this.cIndex]['num'] = Number(this.newsList[this.cIndex]['num']) + 1;
				}
			},
    		async getNewsList() {
				var cateId = 1;
				var page = this.page;
				var limit = this.limit;
				var data = {
					cateId, page, limit
				}
			
				const { data:res } = await this.$http.get('/gongyi/lists', { params: data });
			
				// loading.close();
				if(this.$store.state.beta){
					console.log('视频列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					
					var newsList = res.data.lists;
					for(var i = 0; i < newsList.length; i++) {
						if(newsList[i]['img'] && newsList[i]['img'].substring(0,4) != 'https'){
							newsList[i]['img'] = this.$store.state.http + newsList[i]['img'];
							newsList[i].addtime = newsList[i].addtime.slice(0,10)
						}
						if(newsList[i]['video'] && newsList[i]['video'].substring(0,4) != 'https'){
							newsList[i]['video'] = this.$store.state.http + newsList[i]['video'];
						}
					}
					this.newsList = newsList;
					this.getList1();
				} else {
					this.total = 0;
					this.newsList = [];
				}
			},
			navTo(e){
				 
				  let id = e.target.dataset.id;
				  let aid = e.target.dataset.aid;

			let url = `https://tanpei.ks265.net/#/newsInfo`
			console.log(url);
			 wx.miniProgram.getEnv(function(res){
      		 if (res.miniprogram){
			    wx.miniProgram.navigateTo({url:'/pages/acpage/acpage?src='+url+'&id='+id+'&aid='+aid});
              }else{
						
			 }
      		});
			},
			// 碳排放资格证
			async getList1() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/newIndex',{params:{ cate_id: 9,limit:4}});
				this.$toast.close();
				console.log(res);
				if(this.$store.state.beta){
					console.log('首页校园聚焦：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					
					var data = res.data;
					for(var i = 0; i < data.lists.length; i++) {
					
						if(!data.lists[i]['img']){
							data.lists[i]['img'] = this.$store.state.http + '/Public/uploads/images/2021-03-04/604077d5f1849.png';
						}
						if(data.lists[i]['img'] && data.lists[i]['img'].substring(0,4) != 'http'){
							data.lists[i]['img'] = this.$store.state.http + data.lists[i]['img'];
						}
						data.lists[i]['addtime'] = this.utils.js_date_time(data.lists[i]['addtime'],1);
					}
					let top = data.info;
					top['addtime'] = this.utils.js_date_time(top['addtime'],1)
					top['img'] = this.$store.state.http + top['img'];
					// this.newsList.push(top)
				}
			},
			// 法律法规
			async getList2() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/newIndex',{params:{ cate_id: 3,limit:3}});
				this.$toast.close();
				console.log(res);
				if(this.$store.state.beta){
					console.log('首页法律法规：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var data = res.data;
					for(var i = 0; i < data.lists.length; i++) {
						if(!data.lists[i]['img']){
							data.lists[i]['img'] = this.$store.state.http + '/Public/uploads/images/2021-03-03/603f282e7f13b.jpeg';
						}
						if(data.lists[i]['img'] && data.lists[i]['img'].substring(0,4) != 'http'){
							data.lists[i]['img'] = this.$store.state.http + data.lists[i]['img'];
						}
						data.lists[i]['addtime'] = this.utils.js_date_time(data.lists[i]['addtime'],1);
					}
					this.list1 = data;
				}
			}
		}
	}
</script>

<style scoped>
	.section2{
		margin-top: 0.2rem;
		padding: 0 0.3rem 0.3rem;
	}
	.section2 .cate-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1.04rem;
		padding: 0 0.3rem;
	}
	.section2 .cate-box .cate-list{
		display: flex;
		align-items: center;
		
	}
	.section2 .cate-box .cate-list .item{
		font-size: 0.28rem;
		color: #888888;
		margin-right: 0.45rem;
		height: 0.66rem;
		display: flex;
		align-items: center;
	}
	.section2 .cate-box .cate-list .item:last-child{
		margin-right: 0;
	}
	.section2 .cate-box .cate-list .item.active{
		font-size: 0.32rem;
		color: #333333;
		position: relative;
	}
	.section2 .cate-box .cate-list .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #94c340;
		bottom: 0;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	}
	.section2 .cate-box .more{
		font-size: 0.28rem;
		color: #333333;
	}
	.section2 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		border-radius: 0.145rem;
	}
	.section2 .list .item{
		padding: 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 0.02rem solid #e3e3e3;
		position: relative;
	}
		.section2 .list .item .listmask{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
		}
	.section2 .list .item:first-child{
		border-top: none;
	}
	.section2 .list .item .pic{
		width: 2.1rem;
		height: 1.6rem;
		flex-shrink: 0;
	}
	.section2 .list .item .pic img{
		
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	/* .section2 .list .item .pic .img{
		
	} */
	.section2 .list .item .content{
		width: 4.2rem;
		height: 1.6rem;
		margin-left: 0.1rem;
		flex-shrink: 0;
	}
	.section2 .list .item .content .title{
		font-size: 0.28rem;
		color: #333333;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 0.6rem;
		position: relative;
		line-height: 0.4rem;
		
	}
	.section2 .list .item .content .title .tab{
		font-size: 0.2rem;
		color: #94c340;
		position: absolute;
		right: 0;
		border: 0.02rem solid #94c340;
		height: 0.4rem;
		line-height: 0.38rem;
		padding: 0 0.04rem;
	}
	.section2 .list .item .content .desc{
		font-size: 0.24rem;
		color: #969897;
		line-height: 0.4rem;
		height: 0.8rem;
		overflow: hidden;
	}
	.section2 .list .item .content .time{
		font-size: 0.24rem;
		color: #969897;
		height: 0.4rem;
		line-height: 0.4rem;
		display: flex;
		justify-content: space-between;
		align-content: center;
	}
	.section2 .list .item .content .time .icon{
		display: flex;
		justify-content: center;
		align-content: center;

	}
	.section2 .list .item .content .time .icon img{
		width: 14px;
		margin-right: .08rem;
		object-fit: contain;
	}
	.video-box{
		padding-bottom: 50px;
	}

video::-internal-media-controls-download-button {display:none;}
video::-webkit-media-controls-enclosure {overflow:hidden;}
video::-webkit-media-controls-panel { width: calc(100% + 30px); }
	.video-box .cate-title{
		text-align: center;
		height: 60px;
		line-height: 60px;
		background: #e6e6e6;
		font-size: 18px;
		color: #333333;
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;
		padding: 0 30px;
	}
	.video-box .list{
		/* margin-top: 40px; */
	}
	.video-box .list .item{
		width: 43vw;
		margin:0 3vw;
		padding-bottom: 20px;
		margin-top: 20px;
	}
	/* .video-box .list .item:nth-child(1),
	.video-box .list .item:nth-child(2),
	.video-box .list .item:nth-child(3){
		margin-top: 0;
	}
	.video-box .list .item:nth-child(3n){
		margin-right: 0;
	} */
	.video-box .list .item .pic-box{
		display: block;
		width: 100%;
		height: 180px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		margin-bottom: 25px;
		cursor: pointer;
	}
	.video-box .list .item .pic-box .icon{
		border-radius: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-webkit-transform: translate(-50%,-50%);
		box-shadow: 0 8px 8px rgba(0,0,0,.3);
	}
	.video-box .list .item .title{
		font-size: 1em;
		color: #333333;
		line-height: 25px;
		font-weight: lighter;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.video-box .list .item .view{
		
		font-size: 14px;
		color: #7c7c7c;
		padding-left: 32px;
		background-size: auto;

	}
	
	.alert-box{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0);
		-ms-transform: translate(-50%,-50%) scale(0);
		-o-transform: translate(-50%,-50%) scale(0);
		-moz-transform: translate(-50%,-50%) scale(0);
		-webkit-transform: translate(-50%,-50%) scale(0);
		z-index: 999;
		width: 100%;
		background: #222222;
		transition: all .3s;
	}
	.alert-box.active{
		transform: translate(-50%,-50%) scale(1);
		-ms-transform: translate(-50%,-50%) scale(1);
		-o-transform: translate(-50%,-50%) scale(1);
		-moz-transform: translate(-50%,-50%) scale(1);
		-webkit-transform: translate(-50%,-50%) scale(1);
	}
	.alert-box .title{
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		height: 50px;
		line-height: 50px;
		position: relative;
	}
	.alert-box .title .close{
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 999;
		cursor: pointer;
		width: 10px;
		height: 10px;
		filter: brightness(0);
		filter: invert(1);
	}
	.alert-box .alert-video-box{
		padding: 0 15px 15px;
	}
	.video_title{
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: space-around;
		margin-top: 15px;
	}
	.time{
			font-size: 14px;
		color: #7c7c7c;
	}
</style>
