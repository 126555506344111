<template>
  <div>
    <div class="page_mask" v-if="showMask" @click.stop="stope" @touchmove.prevent>
      <div class="albox">
  <div class="title">提示</div>
      <div class="content">您还未登录,请先登录</div>
      <div class="endbtn" @click="navTo">确定</div>

      </div>
    
    </div>
    <!-- 公共头部 -->
    <Header :bgcol="bgcol" :loginStatus="loginStatus"></Header>
    <div :style="{height:headHeight+'px'}"></div>
    <!-- 首页轮播图 -->
    <!-- <Banner></Banner> -->
   
    <!-- 培训公告 -->
    <Section1></Section1>
    <!-- 快速选择按钮 -->
    <IconList :uid="uid" :loginStatus="loginStatus"></IconList>

    <!-- 校园聚焦和法律法规 -->
    <Section2 ref="section2"></Section2>
    <!-- 轮播公告 -->
    <Section3></Section3>

    <!-- 首页底部 -->
    <IndexFooter></IndexFooter>
    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
     
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Banner from "@/components/Index/components/Banner.vue";
import IconList from "@/components/Index/components/IconList.vue";
import Section1 from "@/components/Index/components/Section1.vue";
import Section2 from "@/components/Index/components/Section2.vue";
import Section3 from "@/components/Index/components/Section3.vue";
import IndexFooter from "@/components/Index/components/IndexFooter.vue";
import Footer from "@/components/Public/Footer.vue";
export default {
  components: {
    Header,
    Banner,
    IconList,
    Section1,
    Section2,
    IndexFooter,
    Footer,
    Section3,
  },
  data() {
    return {
      bgcol: "#fff",
      uid:'',
      loginStatus:'',
      showMask:false,
      headHeight:'',
      page:1,
      limit:10,
      newsList:''
    };
  },
  activated(){
    console.log(this.$refs,'refs');
    this.$refs.section2.getNewsList()
  },
  created() {
  this.$store.commit('saveUid',this.$route.query.uid)
  this.uid =  this.$route.query.uid*1;
  this.loginStatus = this.$route.query.loginStatus;
  },
  mounted() {
    let openid = this.$route.query.openid;
    window.localStorage.setItem('openid',openid)
    let huid = this.$store.state.userInfo.id;
    let options = {openid,huid}
    var h = document.querySelector('.header').clientHeight
    this.headHeight = h

      

  },
  methods: {
    stope(e){
       e.stopPropagation();
    },
  openIndexMask(){
    this.showMask = true
  },
    navTo(){
      let that = this;
     	wx.miniProgram.getEnv(function(res){
      					 if (res.miniprogram){
					    	 wx.miniProgram.switchTab({
                   url:'/pages/my/my',
                   success:function(){
                     that.showMask = false
                   }
                   });
               			}else{
						
						}
      				  });
    },
      //向uniapp传值
getHoid(options){
  document.addEventListener('UniAppJSBridgeReady', function() {
       uni.postMessage({
            data: {
                options
            }
        });
        uni.getEnv(function(res) {
            console.log('当前环境：' + JSON.stringify(res));
        });
    });
},

    async getOpenId() {
      var ua = navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        var openid =
          document.domain == "localhost"
            ? "oawA3t6qV8LQQkHVm3R57nxcp_fc"
            : window.localStorage.getItem("openid");
        var codestr = getRequest();
        var code = codestr["code"];

        if (openid) {
          return false;
        }
        if (code) {
          var data = {
            code,
          };

          this.$toast.loading("加载中...");
          const { data: res } = await this.$http.get("/pay/getOpenid", {
            params: data,
          });
     
          this.$toast.close();
          if (this.$store.state.beta) {
            console.log(
              "获取openid：" +
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds(),
              res
            );
          }
          var code = res.code;
          console.log(res);
           let opid = res.data
            // window.localStorage.setItem("openid", res.data);
          
          if (code == 1) {
           const { data: res } = await this.$http.get("/pay/islogin", {
            params: {openid:opid},
          });
          console.log(res);
    
          if (res.data.islogin==1) {
               this.$store.commit("saveUserInfo", res.data.user);
            window.localStorage.setItem('userInfo',res.data.user)
            //  window.localStorage.setItem('userInfo',res.data.user)
            // window.localStorage.setItem('islogin',res.data.islogin)
          }
     
     
           
          } else {
            this.$toast.error("获取用户openid失败！");
          }
          return false;
        }
        if (!openid && !code) {
          var appid = "wx2187e20afe96c3c0";
          var redirect_uri = encodeURIComponent(this.$store.state.http);
          var response_type = "code";
          var scope = "snsapi_base";
          var state = "123";
          var redirectURI = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;
          location.replace(redirectURI);
          return false;
        }
      }
    },
  },
};

function getRequest() {
  var url = location.search; //获取url中"?"符后的字串
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }

  return theRequest;
}
</script>

<style scoped>
.page_mask{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  background-color: rgba(0, 0, 0,.75);
}
.albox{
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 35px 0;
  z-index: 10;
}
.title{
  font-size: 16px;
  text-align: center;
  padding: 15px 0;
  padding-top: 0;
}
.content{
  font-size: 14px;
  color: #888;
  text-align: center;
  padding-bottom: 15px;
}
.endbtn{
  background-color: #6089f8;
  padding: 8px 20px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  margin-top: 10px;
  cursor: pointer;
  
}
.black{
  height: 4rem;
}

</style>