<template>
	<div class="icon-box">
		<ul class="list clearfix">
		
			<li class="item fl" v-for="(item,index) in iconList"  :key="index">
				<div class="iconmask" :data-path="item.url" @click="navTo"></div>
				<img class="icon" :src="item.img" />
				<h2 class="title">{{item.title}}</h2>
			</li>
		</ul>
	
	</div>
</template>

<script>
	export default {
		props:{
			uid:{
				type:Number
			},
			loginStatus:{
				type:Boolean
			}
		},
		data() {
			return {
				iconList: []
			}
		},
		created(){
			
			this.getIconList()
		},
	
		methods: {
			async getIconList(){
				
			const { data:res } = await this.$http.get('/other/column');
			console.log(res,'icon');
			if (res.code!==1) {
				return this.$messageBox.alert(res.data, '温馨提示');
			}
			this.iconList = res.data

			},
		//paraName 等找参数的名称
			GetUrlParam(src,paraName) {
			var url = src;
			var arrObj = url.split("?");
			if (arrObj.length > 1) {
			var arrPara = arrObj[1].split("&");
			var arr;
			for (var i = 0; i < arrPara.length; i++) {
			arr = arrPara[i].split("=");
			if (arr != null && arr[0] == paraName) {
			return arr[1];
			}
			}
			return "";
			}
			else {
			return "";
			}
			},
			getUrl(pid,type,url){
			return new Promise((resolve,reject)=>{
			if (pid||type) {
			let aurl = url.match(/(\S*)\?/)[1]
			let data = {
				url:aurl,
				type:type
			}
			resolve(data)
			}else{
				let datas = {
					url:url,
					type:type
				}
			resolve(datas)

			}
			})

			},
			navTo(e) {
			
			var timestamp = Date.parse(new Date()) / 1000 + this.$store.state.overDay;
            this.$store.commit("saveOverTime", timestamp);
			let path = e.target.dataset.path;
			
			// this.$router.push({ path: path});return;
			let uid = this.uid;
			
			if (path.indexOf('pages')!==-1) {
				let src;
				if (path!=='/pages/learnCenters/Index') {
					src = path+''
				}else {
					if (this.loginStatus=='true') {
						src = path+''
					}else{
					this.$parent.openIndexMask();
					return false;
					}
					
				}
					wx.miniProgram.getEnv(function(res){
      					 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:src+'?uid='+uid+'&timestamp='+timestamp});
               			}else{
						
						}
      				  });
					}else{
						
						let url = 'https://tanpei.ks265.net/#'+path;
						let id = this.GetUrlParam(url,'id')
						let type = this.GetUrlParam(url,'type')
						console.log(id,'222');
						if (type==3) {
							url ='https://tanpei.ks265.net/#'+path+'&id=8'
							id = this.GetUrlParam(url,'id')
						}
						this.getUrl(id,type,url).then(resolve=>{
						
						wx.miniProgram.getEnv(function(res){
      					if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/webview/web?src='+resolve.url+'&nid='+id+'&uid='+uid+'&type='+resolve.type});
               			}else{
							   
						}
      				  });
						})
					
				
	
				}
	
			
				
			
			}
			
		}
	}
</script>

<style scoped>
	.icon-box{
		padding: 0 0.3rem 0.3rem;
		margin-top: 0.15rem;
	}
	.icon-box .list{
		background: #ffffff;
		border-radius: 0.145rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		padding: 0.3rem 0;
	}
	.icon-box .list .item{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 25%;
		margin-top: 0.36rem;
		position: relative;
	}
	.icon-box .list .item .iconmask{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}
	.icon-box .list .item:nth-child(1),
	.icon-box .list .item:nth-child(2),
	.icon-box .list .item:nth-child(3),
	.icon-box .list .item:nth-child(4){
		margin-top: 0;
	}
	.icon-box .list .item .icon{
		width: 0.88rem;
		height: 0.88rem;
	}
	.icon-box .list .item .title{
		font-size: 0.28rem;
		color: #333333;
	}
	.icon_mask{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba((0), 0, 0, .75);
		z-index: 3;
	}
</style>
