//
//
//
//

export default {
	data() {
		return {
			
		}
	},
	computed: {
		config() {
			return this.$store.state.configInfo;
		}
	},
	methods: {
		
	},
};
